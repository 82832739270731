// @see https://atomiks.github.io/tippyjs/

import tippy from 'tippy.js/esm/index';

export default class Tooltips {
    constructor($container) {
        this.$container = $container;
        this.selector = '[data-tooltip]';
        this.tippys = [];
    }

    init() {
        if (!this.$container || typeof this.$container.querySelectorAll !== 'function') {
            return this;
        }

        const $elements = this.$container.querySelectorAll(this.selector);

        [...$elements].forEach(($element) => {
            const tooltipContent = $element.getAttribute('data-tooltip');

            this.tippys.push(tippy($element, {
                content: tooltipContent,
                theme: 'custom',
                arrow: true,
                trigger: 'mouseenter',
                placement: 'right-start',
                offset: '-24, 0',
            }));
        });

        return this;
    }

    clear() {
        this.tippys.forEach((t) => {
            if (t) {
                t.destroy();
            }
        });
    }
}
