export default class MountainZoneParser {
    constructor(url, apiToken) {
        this.url = url;
        this.apiToken = apiToken;
    }

    async getZone(lat, lng) {
        const response = await fetch(`${this.url}/${lat}/${lng}`, {
            headers: {
                'Berghilfe-Authorization': this.apiToken,
            },
        });
        const data = await response.json();

        return data.zone;
    }
}
