import Vue from 'vue';

import ServiceContainer from '@/vue/services/ServiceContainer';
import MountainZoneParser from '@/vue/services/MountainZoneParser';
import GeoCoordinatesFinder from '@/vue/services/GeoCoordinatesFinder';
import Translator from '@/vue/services/Translator';
import PostApi from '@/vue/services/PostApi';
import Toast from '@/vue/services/Toast';
import StoreFactory from '@/vue/services/StoreFactory';
import LoadingIndicator from '@/vue/services/LoadingIndicator';
import CompanyUidClient from './CompanyUidClient';
import Tooltips from '@/js/components/Tooltips';

export default class VueRelay {
    static parse(domSelector, vueComponent) {
        const $els = [...document.querySelectorAll(domSelector)];
        $els.forEach(($el) => {
            this.initializeVueApp($el, vueComponent);
        });
    }

    static initializeVueApp($el, vueComponent) {
        const vueOptions = JSON.parse($el.dataset.vue || '{}');
        const { state, props } = vueOptions;

        // We create a service container to access the services in all vue components.
        // This allows us to easily mock dependencies in tests.
        const container = new ServiceContainer();

        container
            .set('mountainZoneParser', new MountainZoneParser('/api/mountain-zone', state ? state.apiToken : 'unknown'))
            .set('geoCoordinatesFinder', new GeoCoordinatesFinder('/api/geo-coordinates', state ? state.apiToken : 'unknown'))
            .set('companyUidClient', new CompanyUidClient())
            .set('postApi', new PostApi('/static/files/plz.json'))
            .set('translator', new Translator())
            .set('toast', new Toast())
            .set('loadingIndicator', new LoadingIndicator());

        // A vue component serving as message bus between vue components.
        const eventBus = new Vue();

        // Global stuff available in all vue components.
        Vue.mixin({
            data() {
                return { tooltipsInstance: null };
            },
            mounted() {
                this.tooltipsInstance = new Tooltips(this.$el);
                this.tooltipsInstance.init();
            },
            beforeDestroy() {
                if (this.tooltipsInstance) {
                    this.tooltipsInstance.clear();
                }
            },
            beforeCreate() {
                const options = this.$options;
                if (options.container) {
                    this.$container = options.container;
                } else if (options.parent && options.parent.$container) {
                    this.$container = options.parent.$container;
                }

                if (options.eventBus) {
                    this.$events = options.eventBus;
                } else if (options.parent && options.parent.$events) {
                    this.$events = options.parent.$events;
                }
            },
            methods: {
                t(key, params = {}, domain = null) {
                    return this.$container.get('translator').trans(key, params, domain);
                },
            },
        });

        const vueData = {
            el: $el,
            eventBus,
            container,
            render: h => h(vueComponent, { props }),
        };

        if (state) {
            vueData.store = StoreFactory.create(state);
        }

        (() => new Vue(vueData))();
    }
}
