import DropdownMenu from '@/js/components/DropdownMenu';
import TextareaAutoExpand from '@/js/components/TextareaAutoExpand';
import Toasts from '@/js/components/Toasts';
import Tooltips from '@/js/components/Tooltips';

class App {
    init() {
        const dropdownMenu = new DropdownMenu();
        dropdownMenu.init();

        const textareaAutoExpand = new TextareaAutoExpand();
        textareaAutoExpand.init();

        const toasts = new Toasts();
        toasts.init();

        const tooltips = new Tooltips(document);
        tooltips.init();

        return this;
    }
}

window.addEventListener('DOMContentLoaded', () => {
    const app = new App();
    app.init();
});
