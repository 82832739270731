// eslint-diable class-methods-use-this
export default class PostApi {
    constructor(fallbackUrl) {
        this.fallbackUrl = fallbackUrl;
        this.url = 'https://swisspost.opendatasoft.com/api/records/1.0/search/?dataset=plz_verzeichnis_v2';
    }

    async getLocationsFromPlz(plz) {
        const location = await fetch(`${this.url}&rows=50&q="${plz}"`)
            .then(async response => PostApi.extractLocation(response, plz))
            .catch(async () => {
                const response = await fetch(this.fallbackUrl);
                return PostApi.extractLocation(response, plz);
            });
        return location;
    }

    static async extractLocation(response, plz) {
        const data = await response.json();
        const plzInt = parseInt(plz, 10);
        const locations = data.records.filter(record => (
            (record.fields.gplz === plzInt || record.fields.postleitzahl === plz)
        && record.fields.geo_point_2d));
        return PostApi.removeLocationDuplicates(locations);
    }

    static removeLocationDuplicates(locations) {
        const selectableLocations = [];
        const locationDuplicates = [];
        locations.forEach((location) => {
            if (!locationDuplicates.includes(location.fields.ortbez18)) {
                locationDuplicates.push(location.fields.ortbez18);
                selectableLocations.push(location);
            }
        });

        return selectableLocations;
    }
}
