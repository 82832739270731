import outdatedBrowser from 'outdated-browser-rework';

outdatedBrowser({
    browserSupport: {
        Chrome: 57,
        Edge: 39,
        Safari: 10,
        'Mobile Safari': 10,
        Firefox: 50,
        Opera: 50,
        Vivaldi: 1,
        Yandex: { major: 17, minor: 10 },
        IE: false,
    },
    requireChromeOnAndroid: false,
    isUnknownBrowserOK: true,
    messages: {
        de: {
            outOfDate: 'Ihr Browser ist veraltet!',
            unsupported: 'Ihr Browser wird nicht unterstützt!',
            update: {
                web: 'Bitte aktualisieren Sie Ihren Browser, um diese Website korrekt darzustellen.',
                googlePlay: 'Bitte installieren Sie Chrome von Google Play',
                appStore: 'Bitte aktualisieren Sie iOS in den Einstellungen',
            },
            // You can set the URL to null if you do not want a clickable link or provide
            // your own markup in the `update.web` message.
            url: 'http://outdatedbrowser.com/',
            callToAction: 'Jetzt aktualisieren',
            close: 'Schliessen',
        },
    },
});
