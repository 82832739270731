import Toasts from '@/js/components/Toasts';

export default class Toast {
    constructor() {
        this.toasts = new Toasts();
        this.toasts.init();
    }

    info(message) {
        this.toasts.info('', message);
    }

    error(message) {
        this.toasts.error('', message);
    }

    success(message) {
        this.toasts.success('', message);
    }
}
