export default class ServiceContainer {
    constructor() {
        this.services = {};
    }

    set(id, service) {
        this.services[id] = service;

        return this;
    }

    get(id) {
        return this.services[id];
    }
}
