export default class LoadingIndicator {
    constructor() {
        this.body = document.querySelector('body');
    }

    show(message) {
        this.body.classList.add('is-loading');
        this.body.dataset.loadingText = message;
    }

    hide() {
        this.body.classList.remove('is-loading');
        this.body.dataset.loadingText = '';
    }
}
