export default class TextareaAutoExpand {
    constructor() {
        this.selector = '[data-textarea-auto-expand]';
    }

    init() {
        document.addEventListener('input', (event) => {
            if (event.target.tagName.toLowerCase() !== 'textarea') return;

            this.autoExpand(event.target);
        }, false);

        return this;
    }

    autoExpand($element) {
        const $el = $element;

        // Reset field height
        $el.style.height = 'inherit';

        const computedStyle = window.getComputedStyle($el);

        // Calculate the height
        const height = parseInt(computedStyle.getPropertyValue('border-top-width'), 10)
            // + parseInt(computedStyle.getPropertyValue('padding-top'), 10)
            + $el.scrollHeight
            // + parseInt(computedStyle.getPropertyValue('padding-bottom'), 10)
            + parseInt(computedStyle.getPropertyValue('border-bottom-width'), 10);

        $el.style.height = `${height}px`;

        return this;
    }
}
