import Vuex from 'vuex';

export default class StoreFactory {
    static create(stateData) {
        return new Vuex.Store({
            state: stateData,
            mutations: {
                updateProject(state, project) {
                    // eslint-disable-next-line no-param-reassign
                    state.project = project;
                },
                startLoading(state, text) {
                    // eslint-disable-next-line no-param-reassign
                    state.loadingText = text;
                },
                stopLoading(state) {
                    // eslint-disable-next-line no-param-reassign
                    state.loadingText = '';
                },
            },
            actions: {
                async saveStep({ state }, step) {
                    const response = await fetch(`${state.saveUrl}/${step}`, {
                        method: 'post',
                        headers: StoreFactory.getRequestHeaders(state),
                        body: JSON.stringify(StoreFactory.getRequestBody(state)),
                    });

                    try {
                        return await response.json();
                    } catch (error) {
                        if (response.redirected) {
                            return {
                                message: response.statusText,
                                redirect: response.url,
                            };
                        }
                        return {
                            message: response.statusText,
                            errors: [],
                        };
                    }
                },
                async submitProject({ state }) {
                    const response = await fetch(state.submitUrl, {
                        method: 'post',
                        headers: StoreFactory.getRequestHeaders(state),
                        body: JSON.stringify(StoreFactory.getRequestBody(state)),
                    });

                    return response.json();
                },
                async deleteAttachment({ state }, data) {
                    const url = `${state.uploadDeleteUrl}/${data.attachmentId}/${data.attachmentUid}`;
                    const response = await fetch(url, {
                        method: 'post',
                        headers: StoreFactory.getRequestHeaders(state),
                    });

                    return response.json();
                },
            },
        });
    }

    static getRequestHeaders(state) {
        return {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': state.csrfToken,
        };
    }

    static getRequestBody(state) {
        return {
            project: state.project,
            additionalData: state.additionalData,
        };
    }
}
