export default class GeoCoordinatesFinder {
    constructor(url, apiToken) {
        this.url = url;
        this.apiToken = apiToken;
    }

    async getByAddress(address) {
        const encodedAddress = encodeURI(address);
        const url = `${this.url}/${encodedAddress}`;
        const response = await fetch(url, {
            headers: {
                'Berghilfe-Authorization': this.apiToken,
            },
        });

        return response.json();
    }
}
