import VueRelay from '@/vue/services/VueRelay';
import Vue from 'vue';
import Vuex from 'vuex';
import VueCurrencyInput from 'vue-currency-input';

Vue.use(Vuex);
Vue.use(VueCurrencyInput, {
    globalOptions: {
        currency: 'CHF', locale: 'de-CH', valueAsInteger: true, precision: 0,
    },
});

document.addEventListener('DOMContentLoaded', () => {
    VueRelay.parse('[data-project-edit]', () => import('@/vue/views/EditProject'));
    VueRelay.parse('[data-project-edit-business-report]', () => import('@/vue/views/EditBusinessReport'));
    VueRelay.parse('[data-project-add-attachments]', () => import('@/vue/views/AddAttachments'));
    VueRelay.parse('[data-project-edit-consultant]', () => import('@/vue/components/EditConsultant'));
    VueRelay.parse('[data-dashboard-applicant]', () => import('@/vue/views/DashboardApplicant'));
    VueRelay.parse('[data-dashboard-consultant]', () => import('@/vue/views/DashboardConsultant'));
});
