// @see https://github.com/CodeSeven/toastr

import toastr from 'toastr';

export default class Toasts {
    constructor() {
        this.options = {
            closeButton: false,
            debug: false,
            newestOnTop: false,
            progressBar: false,
            positionClass: 'toast-top-right',
            preventDuplicates: false,
            onclick: null,
            showDuration: '5000',
            hideDuration: '5000',
            timeOut: '8000',
            extendedTimeOut: '1000',
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
        };

        this.toastr = toastr;
    }

    init() {
        this.toastr.options = this.options;

        // toastr.info('Title', 'Message');
        // toastr.success('Title', 'Message');
        // toastr.error('Title', 'Message');
        // toastr.warning('Title', 'Message');

        const flashMessages = document.querySelectorAll('[data-message-flash]');
        if (!flashMessages) {
            return;
        }

        flashMessages.forEach(($elem) => {
            const data = JSON.parse($elem.dataset.messageFlash);
            setTimeout(() => {
                if (data.type === 'success') {
                    this.success('', data.message);
                } else if (data.type === 'error') {
                    this.error('', data.message);
                } else {
                    this.info('', data.message);
                }
            }, 500);
        });
    }

    info(title, message) {
        this.toastr.info(title, message);
    }

    success(title, message) {
        this.toastr.success(title, message);
    }

    error(title, message) {
        this.toastr.error(title, message);
    }

    warning(title, message) {
        this.toastr.warning(title, message);
    }
}
