export default class DropdownMenu {
    constructor() {
        this.selector = '[data-dropdown]';
        this.classOpen = '-open';
    }

    init() {
        document.addEventListener('click', (event) => {
            if (!event.target.parentNode) {
                return;
            }

            // Bail and close if the clicked element doesn't have the right selector
            if (!event.target.parentNode.matches(this.selector)) {
                const allElements = document.querySelectorAll(this.selector);

                if (!allElements) {
                    return;
                }

                [...allElements].forEach(($element) => {
                    $element.classList.remove(this.classOpen);
                });
            }

            const $dropdown = event.target.parentNode;

            // Don't follow the link (optional)
            // event.preventDefault();

            $dropdown.classList.toggle(this.classOpen);
        }, false);

        return this;
    }

    // toggleClass() {
    //
    // }
}
